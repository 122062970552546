import { colors } from "./colors";

export const heatmapData = [
  { id: "US-AK", label: "AK", rate: 0.225 },
  { id: "US-AL", label: "AL", rate: 0.312 },
  { id: "US-AR", label: "AR", rate: 0.327 },
  { id: "US-AZ", label: "AZ", rate: 0.323 },
  { id: "US-CA", label: "CA", rate: 0.374 },
  { id: "US-CO", label: "CO", rate: 0.303 },
  { id: "US-CT", label: "CT", rate: 0.419 },
  { id: "US-DC", label: "DC", rate: 0.597 },
  { id: "US-DE", label: "DE", rate: 0.191 },
  { id: "US-FL", label: "FL", rate: 0.371 },
  { id: "US-GA", label: "GA", rate: 0.352 },
  { id: "US-HI", label: "HI", rate: 0.346 },
  { id: "US-IA", label: "IA", rate: 0.462 },
  { id: "US-ID", label: "ID", rate: 0.224 },
  { id: "US-IL", label: "IL", rate: 0.393 },
  { id: "US-IN", label: "IN", rate: 0.246 },
  { id: "US-KS", label: "KS", rate: 0.317 },
  { id: "US-KY", label: "KY", rate: 0.233 },
  { id: "US-LA", label: "LA", rate: 0.298 },
  { id: "US-MA", label: "MA", rate: 0.523 },
  { id: "US-MD", label: "MD", rate: 0.252 },
  { id: "US-ME", label: "ME", rate: 0.285 },
  { id: "US-MI", label: "MI", rate: 0.241 },
  { id: "US-MN", label: "MN", rate: 0.303 },
  { id: "US-MO", label: "MO", rate: 0.28 },
  { id: "US-MS", label: "MS", rate: 0.383 },
  { id: "US-MT", label: "MT", rate: 0.233 },
  { id: "US-NC", label: "NC", rate: 0.307 },
  { id: "US-ND", label: "ND", rate: 0.222 },
  { id: "US-NE", label: "NE", rate: 0.583 },
  { id: "US-NH", label: "NH", rate: 0.343 },
  { id: "US-NJ", label: "NJ", rate: 0.354 },
  { id: "US-NM", label: "NM", rate: 0.299 },
  { id: "US-NV", label: "NV", rate: 0.499 },
  { id: "US-NY", label: "NY", rate: 0.634 },
  { id: "US-OH", label: "OH", rate: 0.257 },
  { id: "US-OK", label: "OK", rate: 0.252 },
  { id: "US-OR", label: "OR", rate: 0.307 },
  { id: "US-PA", label: "PA", rate: 0.441 },
  { id: "US-PR", label: "PR", rate: 0.085 },
  { id: "US-RI", label: "RI", rate: 0.471 },
  { id: "US-SC", label: "SC", rate: 0.352 },
  { id: "US-SD", label: "SD", rate: 0.353 },
  { id: "US-TN", label: "TN", rate: 0.395 },
  { id: "US-TX", label: "TX", rate: 0.329 },
  { id: "US-UT", label: "UT", rate: 0.586 },
  { id: "US-VA", label: "VA", rate: 0.241 },
  { id: "US-VT", label: "VT", rate: 0.541 },
  { id: "US-WA", label: "WA", rate: 0.344 },
  { id: "US-WI", label: "WI", rate: 0.258 },
  { id: "US-WV", label: "WV", rate: 0.174 },
  { id: "US-WY", label: "WY", rate: 0.206 },
];
export function formatPercent(n: number | undefined): string {
  if (!n) {
    return "";
  }
  const rounded = Math.round(n * 1000) / 10;
  let suffix = "";
  if (rounded === Math.round(rounded)) {
    suffix = ".0";
  }

  return rounded + suffix + "%";
}

export function calculateColor(value: number) {
  let color = null;
  if (value >= 0.05) {
    color = colors["red-4"];
  }
  if (value >= 0.25) {
    color = colors["red-3"];
  }
  if (value >= 0.3) {
    color = colors["red-2"];
  }
  if (value >= 0.36) {
    color = colors["red"];
  }
  return color;
}
export function valueByState(id: string) {
  const found = heatmapData.find((state) => state.id === id);
  if (found) {
    return found.rate;
  }
  return 0;
}
