import * as React from "react";
import "./chart.scss";
import { formatPercent, heatmapData } from "./data/heatmap";
import { regions } from "./data/regions";
export const UsaMapChart: React.FunctionComponent = () => {
  heatmapData;
  regions;

  return (
    <div className="prep-uptake">
      <table>
        {regions.map((region) => (
          <tbody key={region.id}>
            <tr className="red">
              <th colSpan={2} className="text-center">
                {region.name}
              </th>
            </tr>
            <tr className="grey">
              <th>State</th>
              <th>
                PrEP uptake among persons aged{" "}
                <span style={{ fontWeight: "normal" }}>
                  ≥<span className="mini-space">&nbsp;</span>
                </span>
                16 years*
              </th>
            </tr>
            {region.children.map((id) => {
              const data = heatmapData.find((state) => state.id === id);
              if (!data) {
                return null;
              }
              return (
                <tr key={id}>
                  <td>{data.label}</td>
                  <td className="text-center">{formatPercent(data.rate)}</td>
                </tr>
              );
            })}
          </tbody>
        ))}
      </table>
    </div>
  );
};
export default UsaMapChart;
