import * as React from "react";
import type { HeadFC } from "gatsby";
import "../styles/reset.css";
import "../styles/index.scss";
import Layout from "../components/Layout";
import Slab from "../components/Slab";
import Callout, { CalloutLeft } from "../components/Callout";
import Expander, { Separator } from "../components/Expander";
import CtaAidsVu from "../content/CtaAidsVu";
import Endnotes from "../components/Endnotes";
import UsaMap from "../components/UsaMap";

import { StaticImage } from "gatsby-plugin-image";
import DonutGraph from "../components/DonutGraph/DonutGraph";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { UsaMapChart } from "../components/UsaMap/Chart";

import Graph from "../images/1-0/graph.svg";
import GraphMobile from "../images/1-0/graph-mobile.svg";

const IndexPage = () => {
  const isLarge = useMediaQuery("(min-width: 600px)");
  return (
    <Layout>
      <Slab>
        <div className="relative">
          <div className="ml-auto md:w-7/8 h-40 md:h-96 bg-green">
            <StaticImage
              src="../images/hero-1@2x.png"
              className="w-full h-full object-cover object-[center_54%]"
              role="presentation"
              alt=""
            />
          </div>
          <div className="md:absolute top-0 h-full w-full md:w-2/5 lg:w-1/3 xl:w-1/4 flex flex-col justify-center">
            <aside className=" py-8 md:py-24 px-6  bg-lime">
              <h1 className="text-white text-center md:text-left">
                Examining the Impact of PrEP Underutilization
              </h1>
            </aside>
          </div>
        </div>
      </Slab>
      <Slab>
        <div className="my-16 flex">
          <div className="w-1/8 shrink-0"></div>
          <div className="mr-11 md:mr-28">
            <p className="text-header-2">
              While pre-exposure prophylaxis (PrEP) remains a key component of
              national prevention goals to reduce or eliminate new HIV
              infections in the US,{" "}
              <strong className="text-red">
                PrEP is alarmingly underutilized
              </strong>
              .<sup>1-4</sup>
            </p>
          </div>
        </div>
      </Slab>
      <Slab>
        <CalloutLeft>
          <div className="w-full md:w-1/4 p-4 md:p-12">
            <DonutGraph
              percent={0.36}
              alt="Donut graph showing 36%"
              maxWidth={225}
              strokeWidth={9}
            />
          </div>
          <div className="w-3/4 p-4">
            <p className="text-header-2-alt">
              Of the 1.2 million people in the United States indicated for PrEP,{" "}
              <strong>only 36% were prescribed PrEP</strong> in 2022.*
            </p>
            <p className="text-header-2-alt mt-4">
              According to the Centers for Disease Control and Prevention (CDC),
              efforts must be further strengthened and expanded to reach all
              populations equitably and reach our national goals.<sup>5</sup>
            </p>
            <p className="text-body-small mt-4">
              <sup>*</sup>Based on CDC estimates for 2022 for individuals aged ≥
              <span className="mini-space">&nbsp;</span>16 years in the US
            </p>
          </div>
        </CalloutLeft>
      </Slab>
      <Slab>
        <div className="my-8">
          <p className="text-center text-header-2 my-8">
            PrEP uptake in the U.S. varies by state<sup>6</sup>
          </p>
        </div>
        {isLarge ? <UsaMap /> : <UsaMapChart />}
        <p className="text-body-small my-4">
          <sup>*</sup>PrEP uptake (or coverage) is the estimated percentage of
          individuals with indications for PrEP classified as having been
          prescribed PrEP<sup>6</sup>
        </p>
      </Slab>
      <Slab>
        <div className="my-14">
          <div className="md:w-1/3 mx-auto h-3 md:h-5 bg-lime"></div>
          <div className="text-center mt-16 mb-12 md:mx-60">
            <p className="text-header-2">
              Significant disparities persist in PrEP uptake among Black and
              Latino/x populations<sup>7</sup>
            </p>
          </div>
          <div className="md:p-12">
            <div className="hide-mobile">
              <img
                src={Graph}
                className="w-full"
                alt="Chart showing disparities in PrEP usage"
              />
            </div>
            <div className="show-mobile">
              <img
                src={GraphMobile}
                className="w-full"
                alt="Chart showing disparities in PrEP usage"
              />
            </div>
          </div>
        </div>
      </Slab>
      <Slab>
        <div className="my-12">
          <Callout>
            <p className="text-emphasis text-header-2 text-center pb-3 md:pb-5">
              For all PrEP options, maintaining open access is important to
              minimize barriers that may stand in the way of PrEP utilization
              and accommodate diverse individual needs and risk profiles.
            </p>
          </Callout>
        </div>
      </Slab>
      <Slab>
        <div className="flex flex-col md:flex-row ">
          <div className="md:w-1/2 bg-green">
            <div className="w-2/3 mr-auto h-3 md:h-5 bg-lime"></div>
            <div className="h-full flex items-center ">
              <div className="w-1/8 md:w-1/4 shrink-0"></div>
              <h3 className="text-white mx-4 ml-0 md:mr-16 my-10 pb-3 md:pb-5">
                Increasing PrEP utilization may help payers avoid significant
                future costs associated with HIV<sup>8</sup>
              </h3>
            </div>
          </div>
          <div className="md:w-1/2 bg-grey-stone md:h-auto relative">
            <StaticImage
              src="../images/street@2x.png"
              className="w-full h-full object-cover object-right"
              role="presentation"
              alt=""
            />
            <div className="absolute bottom-1 right-1 text-white text-caption text-shadow">
              For illustrative purposes only. Model portrayal.
            </div>
          </div>
        </div>
        <div className="pt-8 pb-4">
          <div className="flex">
            <div className="w-1/8 shrink-0"></div>
            <div className="text-header-2-alt mr-11 md:mr-28">
              <p className="mb-10">
                In a retrospective claims analysis, mean lifetime healthcare
                costs were{" "}
                <strong className="text-red">
                  more than $1.5 million higher*
                  <sup className="daggers low">†</sup>
                </strong>{" "}
                for people living with HIV vs people without HIV in the US
                <sup>8</sup>
              </p>
              <p className="text-body-small my-2">
                * Annual costs were evaluated from 2013 to 2017, and cumulative
                costs of HIV were projected from age 25 to 69 years
              </p>
              <p className="text-body-small my-2">
                <sup>†</sup> Net present value using a 3% discount rate is
                ~$851K in 2017 US dollars
              </p>
            </div>
          </div>
        </div>
      </Slab>
      <Slab>
        <div className="my-4">
          <Expander>
            <div className="flex flex-col md:flex-row items-center bg-grey-stone-15 text-grey-gilead md:py-9">
              <div className="w-full md:w-1/2 text-center text-footnote px-5 my-5 md:my-0">
                The analysis used a large, representative sample of commercially
                insured US adults with HIV to evaluate and project cumulative
                costs of HIV.
              </div>
              <Separator />
              <div className="w-full md:w-1/2 text-center text-footnote px-5 my-5 md:my-0">
                IQVIA claims were used to identify people with HIV and match
                them with people without HIV.
              </div>
            </div>
          </Expander>
        </div>
      </Slab>
      <Slab>
        <div className="my-14">
          <CtaAidsVu>
            Explore local data on{" "}
            <br className="hidden md:inline lg:hidden xl:inline" />
            HIV/AIDS statistics
          </CtaAidsVu>
        </div>
      </Slab>

      <Slab>
        <Endnotes
          references={[
            "Centers for Disease Control and Prevention. Ending the HIV epidemic in the U.S. (EHE). Accessed January 2, 2024. https://www.cdc.gov/endhiv/prevent.html",
            "Centers for Disease Control and Prevention. The state of the HIV epidemic in the U.S. Accessed January 2, 2024. https://www.cdc.gov/nchhstp/newsroom/fact-sheets/hiv/state-of-the-hiv-epidemic-factsheet.html",
            <>
              Streed CG, Morgan JR, Gai MJ, et al. Prevalence of HIV preexposure
              prophylaxis prescribing among persons with commercial insurance
              and likely injection drug use. <em>JAMA Network Open</em>.
              2022;5(7): e2221346. doi:10.1001/jamanetworkopen.2022.21346.
            </>,
            <>
              Goldstein RH, Streed CG, Cahill SR. Being PrEPared — preexposure
              prophylaxis and HIV disparities. <em>N Engl J Med 2018</em>;
              379:1293-1295; doi: 10.1056/NEJMp1804306.
            </>,

            "Centers for Disease Control and Prevention. Expanding PrEP coverage in the United States to achieve EHE goals. Published October 17, 2023. Accessed January 2, 2024. https://www.cdc.gov/nchhstp/dear_colleague/2023/dcl-101723-prep-coverage.html",

            <>
              Centers for Disease Control and Prevention. Core indicators for
              monitoring the ending the HIV Epidemic Initiative (preliminary
              data): National HIV Surveillance System data reported through June
              2023; and preexposure prophylaxis (PrEP) data reported through
              March 2023. <em>HIV Surveillance Data Tables 2023</em>;4(3).
              Published October 2023. Accessed January 2, 2024.
              https://www.cdc.gov/hiv/library/reports/surveillance-data-tables/vol-4-no-3/index.html
            </>,

            <>
              Centers for Disease Control and Prevention. Core indicators for
              monitoring the Ending the HIV Epidemic initiative (preliminary
              data): National HIV Surveillance System data reported through
              March 2023; and preexposure prophylaxis (PrEP) data reported
              through December 2022. <em>HIV Surveillance Data Tables 2023</em>
              ;4(2). Published June 2023. Accessed January 2, 2024.
              https://www.cdc.gov/hiv/library/reports/surveillance-data-tables/vol-4-no-2/index.html
            </>,

            <>
              Cohen JP, Beaubrun A, Ding Y, Wade RL, Hines DM. Estimation of the
              incremental cumulative cost of HIV compared with a non-HIV
              population. <em>Pharmacoecon Open</em>. 2020;4(4):687-696.
              doi:10.1007/s41669-020-00209-8.
            </>,
          ]}
        />
      </Slab>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>PrEP Underutilization</title>;
